import { defineStore, acceptHMRUpdate } from 'pinia';

export const usePricingStore = defineStore('pricing', {
  state: () => ({
    pricingTypeRecordId: 0,
    currentSupplierId: 0,
    currentSupplierName: null,
    wizardType: null,
    steps: [
      {
        id: 1,
        title: 'Pricing type',
        subtitle: 'Select "Feed" or "Manual"',
        href: '#',
        complete: false,
      },
      {
        id: 2,
        title: 'Enter details',
        subtitle: 'Cursus semper viverra.',
        href: '#',
        complete: false,
      },
      {
        id: 3,
        title: 'Review',
        subtitle: 'A summary of your entries.',
        href: '#',
        complete: false,
      },
    ],
    item: null,
    pricings: null,
    activeStep: 1,
  }),
  getters: {},
  actions: {},
  persist: true,
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePricingStore, import.meta.hot));
}
